@tailwind base;
@tailwind components;
@tailwind utilities;

.ruqaa {
    font-family: 'Aref Ruqaa', serif;
}
.inknut {
    font-family: 'Inknut Antiqua', serif;
}
.cormorant {
    font-family: 'Cormorant SC', serif;
}
.playfair {
    font-family: 'Playfair Display', serif;
}
.zen {
    font-family: 'Zen Kaku Gothic New', sans-serif;
}
body {
    font-family: source-han-serif-japanese,serif;
    font-weight: 400;
    font-style: normal;
}

.hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.single-content .aligncenter {
    margin-left: auto;
    margin-right: auto;
}
@layer utilities {
    .single-content h2 {
        @apply text-3xl;
    }
    .single-content h1 {
        @apply text-4xl;
    }
    .single-content h3 {
        @apply text-4xl;
    }
    .single-content h4 {
        @apply text-2xl;
    }
    .single-content h5 {
        @apply text-xl;
    }
    .single-content h6 {
        @apply text-lg;
    }
    .single-content img {
        @apply my-4;
    }

}